<template>
  <HomeView :id="id" :auth="auth" />
</template>

<script>
import HomeView from "@/components/HomeView.vue";
import { useRoute } from "vue-router";
document.title = "Etiqueta digital";
export default {
  name: "Home",

  components: {
    HomeView,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id || route.query.id;
    const auth = route.query.auth || null;

    return {
      id,
      auth,
    };
  },
};
</script>
