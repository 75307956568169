<template>
  <section v-if="!!error">
    <p>
      Lo sentimos, no es posible obtener la información de trazabilidad en este
      momento, por favor intentelo nuevamente mas tarde
    </p>
  </section>

  <el-skeleton style="width: 240px" :loading="loading" animated>
    <template #template>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <el-skeleton :rows="0" />
          </div>
        </template>
        <el-skeleton :rows="5" />
      </el-card>
    </template>
    <template #default>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Trazabilidad</span>
          </div>
        </template>
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(traza, index) in trazabilidad"
              :key="index"
              :timestamp="traza.fecha"
            >
              <h4>{{ traza.titulo }}</h4>
              <p v-if="traza.titulo.includes('Asignado a pedido')">
                <a v-bind:href="traza.detalles">
                  <el-button type="primary" round
                    >Seguimiento del pedido</el-button
                  ></a
                >
              </p>

              <p v-else>
                {{ traza.detalles }}
              </p>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-card>
    </template>
  </el-skeleton>
</template>

<script>
import axios from "axios";
function preprocess(data) {
  for (var i = 0; i < data.length; i += 1) {
    if (data[i].titulo.includes("Asignado a pedido")) {
      data[i].detalles =
        "https://seguimiento.transraser.com/?seguimiento=" + data[i].detalles;
    }
    if (data[i].fecha.includes("00:00:00")) {
      data[i].fecha = data[i].fecha.substring(0, data[i].fecha.length - 8);
    }
  }
  return data;
}
export default {
  name: "Trazabilidad",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    auth: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      idetiqueta: "",
      trazabilidad: null,
      loading: true,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/etiqueta/trazabilidad?id=${this.id}&auth=${this.auth}`
        )
        .then((response) => {
          this.trazabilidad = preprocess(response.data);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response.status;
        })
        .finally(() => (this.loading = false));
    });
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: bold;
}
.el-timeline-item h4,
p {
  margin-bottom: 4px;
  margin-top: 4px;
}
.el-timeline {
  padding-left: 10px;
  padding-right: 10px;
}

.card-header img {
  max-height: 35px;
}

.box-card {
  max-width: 800px;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.el-col {
  border-radius: 4px;
}
.margin0 {
  margin: 3px;
}

.el-timeline-item__tail {
  top: 6px !important;
}
</style>
