<template>
  <el-card class="box-card">
    <div class="card-header">
      <span>Feedback</span>
    </div>
    <p>
      Si esta aplicación ha sido útil o crees que la podemos mejorar, por favor,
      dinos lo que piensas sobre ella.
    </p>

    <el-button @click="drawer = true" color="#A6C616" type="success" round
      >Dar mi opinión</el-button
    >
  </el-card>

  <el-drawer title="Feedback" v-model="drawer" size="85%" :with-header="false">
    <iframe v-bind:src="link" />
  </el-drawer>
</template>

<script>
export default {
  name: "Feedback",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      drawer: false,
      link:
        "https://docs.google.com/forms/d/e/1FAIpQLScB_-11AIhYu-tzy5iGAV-aC_mfd81X41qwerLJ2By_QyLKmQ/viewform",
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.link =
        "https://docs.google.com/forms/d/e/1FAIpQLScB_-11AIhYu-tzy5iGAV-aC_mfd81X41qwerLJ2By_QyLKmQ/viewform?entry.2111035266=" +
        this.id;
    });
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: bold;
}
.card-header img {
  max-height: 35px;
}

.box-card {
  max-width: 450px;
}
iframe {
  width: 100%;
  height: 100%;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.el-col {
  border-radius: 4px;
  padding-left: 0px;
}
.margin0 {
  margin: 3px;
}
</style>
