<template>
  <el-container class="wrapper">
    <el-header>
      <img src="@/assets/logo.png" alt="Transraser logo" class="header-logo" />
      <a>Etiqueta digital</a>
      <!--
      <router-link to="/about">AR</router-link> 
      -->
    </el-header>
    <el-main>
      <el-col>
        <Etiqueta :id="id" :auth="auth" />
      </el-col>
      <el-col>
        <Trazabilidad :id="id" :auth="auth" />
      </el-col>
      <el-col>
        <Feedback :id="id" />
      </el-col>
    </el-main>
  </el-container>
</template>

<style src="@/views/Home/Home.css" scoped></style>

<script>
import Etiqueta from "@/components/Etiqueta.vue";
import Trazabilidad from "@/components/Trazabilidad.vue";
import Feedback from "@/components/Feedback.vue";
document.title = "Etiqueta digital";
export default {
  name: "HomeView",
  props: ["id", "auth"],
  components: {
    Etiqueta,
    Trazabilidad,
    Feedback,
  },
};
</script>
