<template>
  <div>
    <h1>Servicio no disponible</h1>
    <img src="@/assets/iphone.png" />
  </div>
</template>

<script>
export default {
  name: "NoDisponible",
};
</script>

<style scoped>
h1,
div {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
</style>
