<template>
  <router-view :key="$route.path" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
}

.el-header {
  background-color: #343a40;
  color: rgb(255, 255, 255);
  font-size: 24px;
  align-items: center;
}

.el-header img {
  vertical-align: middle;
  height: 30px;
  padding: 15px;
}
main .el-col {
  padding: 10px;
}
</style>
