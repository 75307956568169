<template>
  <div>
    <h3 v-if="etiquetas.length > 0">Material compuesto por multiples bultos</h3>
    <div v-for="etiqueta in etiquetas" :key="etiqueta.etiqueta" class="items">
      <router-link
        class="etiqueta-padre"
        :to="{
          name: 'Etiqueta',
          params: { id: etiqueta.etiqueta },
          query: { auth: etiqueta.key },
        }"
      >
        {{ etiqueta.etiqueta }}
      </router-link>
      <div class="items__container">
        <router-link
          :to="{
            name: 'Etiqueta',
            params: { id: etiquetahijo.etiqueta },
            query: { auth: etiquetahijo.key },
          }"
          v-for="etiquetahijo in etiqueta.hijos"
          :key="etiquetahijo.etiqueta"
          :class="etiquetahijo.etiqueta === idetiqueta ? 'active' : ''"
          >{{ etiquetahijo.etiqueta }} - {{ etiquetahijo.observaciones }}
          <span>{{ etiquetahijo.cantidad }} Ud.</span></router-link
        >
      </div>
    </div>
    <el-divider v-if="etiquetas.length > 0"></el-divider>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Descomposicion",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    auth: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      idetiqueta: "",
      etiqueta: null,
      etiquetas: [],
      loading: true,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.idetiqueta = this.id;

      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/etiqueta/relaciones?id=${this.id}&auth=${this.auth}`
        )
        .then((response) => {
          this.etiqueta = response.data;
          response.data.forEach((etiqueta) => {
            console.log(etiqueta);
            if (
              !this.etiquetas.some(
                (data) => data.etiqueta === etiqueta["numero_padre"]
              )
            ) {
              //don't exists
              this.etiquetas.push({
                etiqueta: etiqueta["numero_padre"],
                key: etiqueta["numero_padre_key"],
                hijos: [],
              });
            }
            let indice = this.etiquetas.findIndex(
              (data) => data.etiqueta === etiqueta["numero_padre"]
            );
            this.etiquetas[indice].hijos.push({
              etiqueta: etiqueta["numero_hijo"],
              key: etiqueta["numero_hijo_key"],
              observaciones: etiqueta["observaciones"],
              cantidad: etiqueta["cantidad"],
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response.status;
        })
        .finally(() => (this.loading = false));
    });
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: bold;
}
.card-header img {
  max-height: 35px;
}

.box-card {
  max-width: 800px;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.el-col {
  border-radius: 4px;
  padding-left: 0px;
}
.margin0 {
  margin: 3px;
}
.items {
  display: flex;
  width: 100%;
}

.items .etiqueta-padre {
  margin: 0;
  min-width: 80px;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 10px;
}

.items__container {
  border-left: 2px solid #e4e7ed;
  width: 70%;
}

.items__container a {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
}

.items__container a:nth-child(odd) {
  background-color: #efefef;
}

.items__container a:nth-child(even) {
  background-color: #fafafa;
}

.items__container a.active {
  background-color: rgb(27 231 42 / 20%);
}

a {
  text-decoration: none;
  color: initial;
}

@media (max-width: 469px) {
  .items {
    flex-direction: column;
  }
}

@media (max-width: 469px) {
  .items__container {
    width: 100%;
  }
}
</style>
