<template>
  <section v-if="error">
    <section v-if="error == 503"><NoDisponible /></section>
    <section v-else>
      <p>
        Lo sentimos, no es posible obtener la información de la etiqueta en este
        momento, por favor intentelo nuevamente mas tarde
      </p>
    </section>
  </section>

  <el-skeleton style="width: 240px" :loading="loading" animated>
    <template #template>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>{{ idetiqueta }}</span>
            <el-skeleton-item
              variant="image"
              style="width: 60px; height: 30px"
            />
          </div>
        </template>
        <el-skeleton :rows="10" />
      </el-card>
    </template>
    <template #default>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Etiqueta: {{ etiqueta.id }}</span>
            <img :src="require(`@/assets/logo-${etiqueta.id_cliente}.png`)" />
          </div>
        </template>
        <el-row class="etiqueta__header">
          <el-col :span="16">
            <div>
              <h3 style="display: inline" class="margin0">Material:&nbsp;</h3>
              <h4 class="margin0">{{ etiqueta.codigo }}</h4>
              <p class="margin0">{{ etiqueta.descripcion }}</p>
            </div>
          </el-col>
          <el-col :span="7">
            <el-row>
              <div>
                <h3 style="display: inline">Cantidad:&nbsp;</h3>
                <p style="display: inline">{{ etiqueta.cantidad }}</p>
              </div>
            </el-row>
            <el-row>
              <div>
                <h3 style="display: inline">Peso:&nbsp;</h3>
                <p style="display: inline">{{ etiqueta.peso }} Kg</p>
              </div>
            </el-row>
            <el-row>
              <div>
                <h3 style="display: inline">Medidas:&nbsp;</h3>
                <p style="display: inline" v-if="etiqueta.medida_largo">
                  {{ etiqueta.medida_largo }} x {{ etiqueta.medida_ancho }} x
                  {{ etiqueta.medida_alto }} cm
                </p>
                <p style="display: inline" v-else>No disponible</p>
              </div>
            </el-row>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <div v-if="etiqueta.descomposicion">
          <Descomposicion v-bind:id="etiqueta.id" v-bind:auth="etiqueta.auth" />
          
        </div>
        <h3>Información adicional</h3>
        <p v-show="etiqueta.fabricante">
          <strong>Proveedor: </strong>{{ etiqueta.fabricante }}
        </p>
        <p v-show="etiqueta.lote"><strong>Lote: </strong>{{ etiqueta.lote }}</p>
        <p v-show="etiqueta.valoracion"><strong>Valoración: </strong>{{ etiqueta.valoracion }}</p>
        <p v-show="etiqueta.sn">
          <strong>Número de serie: </strong>{{ etiqueta.sn }}
        </p>
        <p v-show="etiqueta.observaciones">
          <strong>Observaciones: </strong>{{ etiqueta.observaciones }}
        </p>
        <p v-show="etiqueta.reserva">
          <strong>Reserva/posición: </strong> {{ etiqueta.reserva }} /
          {{ etiqueta.posicion }}
        </p>
        <p v-show="etiqueta.imputacion">
          <strong>Objeto de imputación: </strong> {{ etiqueta.imputacion }} -
          {{ etiqueta.desc_imputacion }}
        </p>
        <p v-show="etiqueta.fecha_caducidad">
          <strong>Caducidad: </strong> {{ etiqueta.fecha_caducidad }}
        </p>

        <div v-if="etiqueta.origen_id">
          <el-collapse>
            <el-collapse-item
              :title="'Cortado o extraido del bulto ' + etiqueta.origen_id"
            >
              <Etiqueta
                v-bind:id="etiqueta.origen_id"
                v-bind:auth="etiqueta.origen_auth"
              />
            </el-collapse-item>
          </el-collapse>
        </div>
        <!--
        <div v-for="(value, name) in etiqueta" :key="name" class="text item">
          <div v-if="value !== null">
            {{ name + ": " + value }}
          </div>
        </div>
        -->
      </el-card>
    </template>
  </el-skeleton>
</template>

<script>
import NoDisponible from "@/components/NoDisponible";
import Descomposicion from "@/components/Descomposicion";
import axios from "axios";
export default {
  name: "Etiqueta",
  components: {
    NoDisponible,
    Descomposicion,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    auth: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      idetiqueta: "",
      etiqueta: null,
      loading: true,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.idetiqueta = this.id;

      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/etiqueta?id=${this.id}&auth=${this.auth}`
        )
        .then((response) => {
          this.etiqueta = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response.status;
        })
        .finally(() => (this.loading = false));
    });
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  font-weight: bold;
}
.card-header img {
  max-height: 35px;
}

.box-card {
  max-width: 800px;
}

@media (max-width: 800px) {
  .box-card {
    max-width: 100%;
  }
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.el-col {
  border-radius: 4px;
  padding-left: 0px;
}
.margin0 {
  margin: 3px;
}

@media (max-width: 469px) {
  .etiqueta__header {
    flex-wrap: wrap;
  }

  .etiqueta__header .el-col {
    width: 100%;
    max-width: 100%;
    flex: 0 1 auto;
  }
}
</style>
